.inputBox {
    margin-bottom: 23px;
}

.inputLocation {
    flex-wrap: wrap;

    &>div {
        border-bottom: 0;

        & svg {
            display: none;
        }
    }

    & input {
        &:hover {
            border-bottom: 1px solid #b8bfd1;
        }
    }
}

.box {
    margin-top: 22px;
}

.descriptionBox {
    & p {
        word-break: break-all;
        max-height: 200px;
    }
}

.removeButton {
    border: 1px solid red;
    border-radius: 20px;
    color: red;
    padding: 10px 15px;
    text-transform: capitalize;
    margin-bottom: 20px;
    cursor: pointer;
    &:hover{
        background-color: red;
        color: white;
    }
}

.addButton {
    /* background-color: rgba(0, 76, 255, 0.667); */
    border-radius: 5px;
    color: #CDF0A6;
    padding: 10px 15px;
    margin-bottom: 20px;
    width:80%;
    cursor: pointer;
    align-items: center;
}

.additionalLinkInput {
    margin-top: 20px;

    & button {
        margin-bottom: 10px;
    }
}

.pickupTime {
    margin-top: 23px;
    margin-bottom: 23px;
}

.daysCheckBox {
    display: flex;
    flex-direction: row;
    gap: 10px;

    & input[type="checkbox"] {
        width: 20px;
    }
}

.daysTitle {}
.daysTitleButton{
    text-decoration: underline;
    color: rgb(0, 162, 255);
}

.daysValue {
    margin-top: 22px;
    &>div {
        margin-bottom: 23px;
    }
}

.rowInputBox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .inputBox {
        width: calc(50% - 9px);
        margin-bottom: 0;
    }
}
.bottomButton{
    padding-bottom: 20px;

    @media (max-width:768px) {
        padding-bottom: 125px;
    }
}

.cancelPolicyWrapper {
    display: flex;
    column-gap: 18px;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  
    @media (max-width:768px) {
      margin: 20px 0;
    }
  
    & .cancelText {
    color: #717171;
    font-family: IBM Plex Sans Thai Looped;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    }
  
    & .inputRow {
      width: 70px;
    }
  }